@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Petit+Formal+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kavivanar&display=swap);
/* RESET
--------------------------------- */
/* #region  */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {line-height: 1.2rem;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* box model to border-box */
html {
  box-sizing: border-box;
}

/* #endregion */

/* The main point of styling */
body {
  font-size: 15px;
  margin: 0 auto;
  box-sizing: border-box;
  scroll-behavior: smooth;


  min-width: 319px;
  background-color: #333533;

  font-family: Raleway, "Noto Sans JP", sans-serif;
}

strong {
  font-weight: 700;
}

.Contacts {
  position: relative;
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;

}

.contact-icon {
  margin: 0.2rem 1rem;
  color: rgba(250, 235, 215, 0.466);
  font-size: 1.8rem;
  transition: ease 1000ms;
  cursor: pointer;
  text-shadow: 1px 1px 1px #88a01f;
}

.contact-icon:hover {
  color: rgba(255, 255, 255, 0.767);
  transition: ease 1000ms;
}

/* --- APPS CONTAINER --- */

.course-button {
  margin-bottom: 1rem;
  background-color: #000000d7;
  color: #ece5e5f3;
  font-weight: 400;
  font-size: 1rem;
  text-decoration: none;
  padding: 1rem 2em;
  outline: none;
  transition: 0.4s ease-in;
}

.course-button:hover {
  color: #f3edf3;
  background-color: #df0404;
  text-shadow: 1px 1px 5px #000000d7;
}


.AppsContainer {
  padding: 2rem 0;
}

.apps-topbar {
  padding: 2rem;
  margin-bottom: 1rem;
}

.apps-navbar {
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

.tab-button {
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid #1d1d1d42;
}

.tab-button-active {
  border-bottom: none;
  border-top: 1px solid #1d1d1d42;
  border-left: 1px solid #1d1d1d42;
  border-right: 1px solid #1d1d1d42;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 1rem;
  cursor: pointer;
}

.apps-view {
  padding: 2rem 8vw;
  min-height: 720px;
}

.app-component {
  margin: 1rem;
}

.pbi-dashboard {
  width: 100%;
  height: 50vw;
  cursor: pointer;
}


/* --- LEAVE A MESSAGE APP --- */
.LAM {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.LeaveAMessage {
  width: 60%;
  max-width: 500px;
}

.LeaveAMessage form * {
  box-sizing: border-box;
}

.LeaveAMessage form {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  justify-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 3rem;
}

.LeaveAMessage form input {
  width: 100%;
}

.LeaveAMessage form input[type=text] {
  padding: .8rem;
  margin: .8rem 0;
  border: 1px solid #eee;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  font-family: inherit;
  font-size: inherit;
}

.LeaveAMessage form textarea[type=text] {
  padding: .5rem;
  margin: .8rem 0;
  border: 1px solid #eee;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  width: 100%;
  min-height: 100px;
  font-family: inherit;
  font-size: inherit;
  resize: vertical;
  overflow: auto;
  transition: 1s ease-in-out;
}

.keep-it-private {
  position: relative;
  margin: 1rem;
}

.keep-it-private input[type=checkbox] {
  position: absolute;
  width: auto;
}

.form-controller {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.LeaveAMessage form button {
  appearance: none;
  -webkit-appearance: none;
  padding: .7rem;
  margin: 1rem;
  border: none;
  background-color: #39393b;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  width: 8rem;
  font-family: inherit;
  font-size: inherit;
  text-transform: uppercase;
  cursor: pointer;
}

.LeaveAMessage form button:active {
  background-color: #1d1d1d;
  color: #fff;
  transition: ease 100ms;
}

.MessagesList {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 1rem 1vw;
  padding-bottom: 2rem;
  width: 30%;
  height: 500px;
  overflow-y: auto;
}

.message-list-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin: 1rem 0;
}

.message-list-item h1 {
  font-weight: 700;
  text-transform: uppercase;
}

.message-list-item p {
  margin-bottom: .5rem;
  text-align: justify;
}

.message-list-item h2 {
  font-size: .7rem;
  font-style: italic;
}


/* --- XO GAME APP --- */

.XO-board {
  box-sizing: border-box;
  border: 2px solid #39393b88;
  background-color: #16161611;
  grid-gap: 1rem;
  padding: 0.8rem;
  border-radius: 10px;
  max-width: 240px;
  min-width: 220px;
  min-height: 220px;
  height: 240px;
  margin: 5rem auto;
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(3, 1fr);
}

.XO-status {
  color: #850505f3;
  font-size: 1.5rem;
  margin: 1rem 0.5rem;
}

.XO-play-again-button {
  margin: 1rem 0.5rem;
  padding: 0.5rem;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.XO-square {
  text-align: center;
  vertical-align: middle;
  background: #fff;
  border: 2px solid #39393b88;
  /* border-radius: 10px; */
  padding: 1.1rem;
  font-size: 1.7rem;
  font-weight: 300;
  cursor: pointer;
  outline: none;
  height: 1.2rem;
  width: 1.2rem;
}


/* --- TEST YOUR KNOWLEDGE --- */
.TYK {

  padding: 4rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 5%;
}

.TYK-question-box {
  margin: 1rem;
  box-shadow: 0 0 10px #0000002a;
  width: 300px;
  padding: 1rem;
}

/* --- TECH NEWS --- */
.news-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 5%;
}

.news-item {
  margin: 1rem;
  /* background-color: #0000002a; */
  box-shadow: 0 0 10px #0000002a;
  width: 300px;
  padding: 1rem;

}

/* --- RESUME --- */
.Resume {
  padding: 2rem;
  /* background-color: #1d1d1d13; */
  min-height: 1024px;
}

.resume-title {
  font-size: 3rem;
  font-weight: 900;
  letter-spacing: 1.5rem;
  margin-top: 20rem;
  color: #0a0a0ac0;
  text-shadow: 0 0 5px #00000046;
  /* background-image: url(https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fd2v9y0dukr6mq2.cloudfront.net%2Fvideo%2Fthumbnail%2FGfRLKaE%2Fvideoblocks-abstract-business-background_rofncpzb_thumbnail-full01.png&f=1&nofb=1);
  padding: 10vw 0;
  background-size: cover; */
}

.resume-hint-box {
  padding: 5rem 10vw;
  padding-top: 15rem;
  font-size: 1.3rem;
  line-height: 2rem;
}

.resume-exp-card {
  margin: 21rem 10vw;
  padding: 2vw;
  text-align: justify;
  color: rgb(32, 32, 32);
  font-size: 1.1em;
  line-height: 1.6rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;

  /* background-color: #0000002a; */

}

/* .resume-exp-card:nth-child(even) .resume-exp-logo-block {
  order: 2;
} */

.resume-exp-logo-block {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  justify-self: center;
}

.resume-exp-employer-logo {
  /* background-color: #0000002a; */
  -webkit-align-self: flex-start;
          align-self: flex-start;
  justify-self: center;
  min-width: 180px;
  max-height: 180px;
  margin-bottom: .2rem;
}

.resume-exp-card-data {
  /* background-color: #0000002a; */
  -webkit-align-self: center;
          align-self: center;
  justify-self: center;
  padding: 1rem 0;
  padding-right: 3vw;
}

.resume-exp-dates {
  /* background-color: #0000002a; */
  font-size: 1.2rem;
  font-weight: 300;
  padding: .5rem 0;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
}

.resume-exp-employer {
  /* background-color: #72252596; */

}

.resume-exp-role {
  font-size: 1.2rem;
  font-weight: 500;
  padding: .2rem 0
}

.resume-exp-description-point {
  /* background-color: #3d833ac0; */
  margin: .8rem auto;
  /* list-style: disc inside; */
}


/*
------------------------
main app style
------------------------
*/
.App {
  text-align: center;
  background-color: rgb(245, 245, 245);
  cursor: default;
}

/*
------------------------
header style
------------------------
*/

.Header {
  background-color: #850505f3;
  padding: 1rem 5vw;
  min-height: 850px;
  position: relative;
}

.Header::before {
  content: "";
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.08;
  background-image: url(/static/media/17545.7a872149.jpg);
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

/*
------------------------
navbar style 
------------------------
*/

.NavBar {
  position: -webkit-sticky;
  position: sticky;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: auto 1rem;
}

.NavBar a {
  text-decoration: none;
}

.blogTitle {
  margin: 0.8rem 1vw;
  font-size: 1.4rem;
  font-weight: 500;
  color: whitesmoke;
}

.group {
  text-transform: uppercase;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: auto;
  margin: 1rem;
  /* // background-color: #1d6086; */
}

.element {
  color: #ece5e5a6;
  font-weight: 400;
  font-size: 1rem;
  text-decoration: none;
  padding: 1rem 2em;
  outline: none;
  transition: 0.5s ease-in;
}

.element:hover {
  color: #f3edf3;
  background-color: #df0404;
  text-shadow: 1px 1px 5px #000000d7;
}

/*
------------------------
home banner style 
------------------------
*/

.HomeBanner {
  /* // background-color: #282c34; */
  background-color: #0000002a;
  padding-top: 1rem;
  padding: 1rem 2vw;
  margin: 1rem 0;
  /* // margin: 1rem 0; */
}

.HomeBanner p {
  font-weight: 100;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.profile-image {
  margin: 1rem;
  border-radius: 50%;
  width: 12vw;
  max-width: 15vw;
  min-width: 10vw;
  -webkit-filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5)) sepia(0%) opacity(50%) contrast(200%) brightness(90%);
          filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5)) sepia(0%) opacity(50%) contrast(200%) brightness(90%);
  /* opacity: 80%; */
}

.bio {
  color: rgba(245, 245, 245, 0.644);
  text-shadow: 0px 0px 5px #f3f0f025;
  margin-bottom: 3rem;
  margin-top: 1rem;
  -webkit-filter: sepia(50%);
          filter: sepia(50%);
}

.bio h1 {
  font-size: 1.5rem;
  letter-spacing: 0.2vw;
  margin-bottom: 1rem;
  font-family: "Petit Formal Script";
}

.bio p {
  font-weight: 200;
}

/*
------------------------
skills banner style 
------------------------
*/

.SkillsBanner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  color: rgba(245, 245, 245, 0.548);
  padding: 1rem 1vw;
}

.skills-group {
  -webkit-filter: sepia(100%);
          filter: sepia(100%);
  margin: 1rem;
  margin-bottom: 0.5rem;
}

.skill {
  font-size: 2rem;
  padding: 0.5rem;
}

/*
------------------------
HomeMainView style 
------------------------
*/


/*
------------------------
Learn with me gallary style 
------------------------
*/

.LWM-section-title {
  /* position: relative; related to the background-img */
  padding: 1rem 0;
  margin: 3rem auto;
  font-size: 3rem;
  font-weight: 500;
  color: #2c2c2cde;
  text-shadow: 1px 1px 5px #00000034;
}

.LWM-gallary {
  /* position: relative; related to the background-img */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 1rem;
  margin: 1rem 1vw;
  color: #2c2c2cde;
  text-shadow: 1px 1px 5px #00000034;
}

.blog-gallary-card {
  /* position: relative; */
  margin: 0.5rem;
  padding: 1.5rem 1.5vw;
  width: 280px;
  background-color: #00000008;
  /* box-shadow: 1px 1px 10px #00000005; */
}

.card-text {
  text-align: justify;
  font-weight: 400;
  line-height: 1.4rem;
}

.card-img {
  margin-bottom: 1rem;
  max-width: 280px;
  box-shadow: 1px 1px 10px #00000034;
}

.titles-box {
  margin-bottom: 1rem;
  text-align: left;
  font-size: 1.2rem;
}

.card-title {
  font-weight: 900;
}

.card-subtitle {
  font-weight: 400;
}




/*
------------------------
Hint style 
------------------------
*/

.hint-center {
  color: #ece9e9bb;
  padding: 5rem 10vw 1rem 10vw;
  text-shadow: 0px 0px 5px #0a0a0ac0;
}

.hint-icon {
  font-size: 3rem;
  padding: 1vw;
}


.hint-title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.8rem;
}

.hint-text {
  line-height: 1.8rem;
  font-size: 1.2rem;
  font-weight: 300;
  padding: auto 2vw;
}

/*
------------------------
What Can I Do style 
------------------------
*/

.Footer {
  padding: 0.5rem 5vw;
  min-height: 109px;
  color: rgb(243, 237, 243);
  /* background-color: #850505e5; */
  /* background-color: #000000e5; */
  background-color: #740505e5;
}

.footer-text {
  font-size: 1rem;
  font-weight: 400;
  /* padding: 0.8rem 0rem 0.5rem 0rem; */
  color: rgba(230, 221, 211, 0.466);
}

/*
------------------------
responsive design 
------------------------
*/

@media screen and (max-width: 1024px) {
  .resume-title {
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: .6rem;
    margin-top: 20rem;
  }

  .resume-hint-box {
    padding: 5rem 10vw;
    font-size: 1rem;
    line-height: 2rem;
    text-align: justify;
  }

  .resume-exp-card {
    margin: 20rem 5vw;
    padding: 2rem 2vw;
    text-align: justify;
    /* font-size: 1em; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .resume-exp-logo-block {
    -webkit-align-self: center;
            align-self: center;
    justify-self: center;
  }

  .resume-exp-employer-logo {

    -webkit-align-self: flex-start;

            align-self: flex-start;
    justify-self: center;

    min-width: 120px;
    max-height: 120px;
  }

  .resume-exp-dates {
    font-size: 1rem;
  }

  .resume-exp-card-data {
    /* background-color: #0000002a; */
    -webkit-align-self: center;
            align-self: center;
    justify-self: center;
    padding: 1rem 0;
  }

  .resume-exp-role {
    text-align: start;
  }

  .resume-exp-employer {
    text-align: start;
  }

  .LAM {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }

  .LeaveAMessage {
    width: 100%;
  }

  .LeaveAMessage form {
    width: 80vw;
  }

  .LeaveAMessage form button {
    padding: .5rem;
    width: 5rem;
  }

  .MessagesList {
    /* display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 1vw;
    padding-bottom: 2rem; */
    padding: 2rem 2rm;
    width: 100%;
    height: 500px;
    /* overflow-y: auto; */
  }

  .news-list {
    padding: 0;
  }

  .App::before {
    content: "";
    background-size: 2200px;
  }

  .Header::before {
    content: "";
    background-size: 2200px;
  }

  .NavBar {
    padding: 0.9rem 0;
  }

  .blogTitle {
    font-size: 1.3rem;
  }

  .group {
    display: none;
  }

  .element {
    display: none;
  }


  .profile-image {
    margin: 0.5rem 0.5vw;
  }

  .bio {
    margin-top: 0;
  }

  .bio h1 {
    font-size: 1.1rem;
  }

  .bio p {
    font-size: 1rem;
  }

  .Hint {
    padding: 4rem 4vw;
  }

  .hint-text {
    font-size: 1rem;
    font-weight: 300;
  }


  .hint-icon {
    font-size: 3rem;
    padding: 1vw;
  }

  .hint-title {
    font-size: 1.3rem;
    font-weight: 700;
  }

  .hint-text {
    text-align: justify;
    line-height: 1.8rem;
    font-size: 1.2rem;
    font-weight: 300;
    padding: auto 2vw;
  }
}

@media screen and (max-width: 319px) {
  .LeaveAMessage form {
    width: 250px;
  }

  .profile-image {
    display: none;
  }

}
